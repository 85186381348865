// 是否包含非法字符
const passReg = (val) => {
  return /[^\w!@#$%_.?]+/g.test(val)
}
// 是否包含数字
const numReg = (val) => {
  return /[0-9]+/g.test(val)
}
// 是否包含a-zA-Z
const letterReg = (val) => {
  return /[a-zA-Z]+/g.test(val)
}
// 是否包含!@#$%_.?
const codeReg = (val) => {
  return /[!@#$%_.?]+/g.test(val)
}
// 手机号验证
const phoneReg = (val) => {
  return /^1[34578]\d{9}$/.test(val)
}
// 固定电话验证
const telePhoneReg = (val) => {
  return /^(\(\d{3,4}\)|\d{3,4}-|\s)?\d{7,14}$/.test(val)
}
// 邮箱
const emailReg = (val) => {
  return /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/.test(val)
}
// 基金备案号
const fundRecordNumReg = (val) => {
  return /^P[a-zA-Z0-9]+$/.test(val)
}
const postCodeReg = (val) => {
  return /^[0-9]{6}$/.test(val)
}
// 法定代表人姓名验证
const personNameReg = (val) => {
  return /^([a-zA-Z]|[\u4e00-\u9fa5])+$/.test(val)
}

export default {
  passReg, numReg, letterReg, codeReg, phoneReg, emailReg, fundRecordNumReg, telePhoneReg, postCodeReg, personNameReg
}
