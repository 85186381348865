<template>
  <div class="change-content-page">
    <h6 class="ym-title">
      <el-link class="ym-title-link" href="#/setting">设置 <i class="el-icon-arrow-right"></i> </el-link>
      <span>{{ title }}</span>
    </h6>
    <div class="ym-panel-general">
      <el-form :model="form" :rules="rules" ref="form" label-width="282px">
        <el-form-item>
          <div class="shenhe-content">
            <i
              v-if="form.auditFlag === 1"
              class="iconfont icondengdai warning"
            ></i>
            <i
              v-else-if="form.auditFlag === 2"
              class="iconfont icondengdai warning"
            ></i>
            <i
              v-else-if="form.auditFlag === 3"
              class="iconfont iconshenhetongguo success"
            ></i>
            <i
              v-else-if="form.auditFlag === 4"
              class="iconfont icontishi danger"
            ></i>
            <span v-if="form.auditFlag === 1" class="shenhe-title warning">{{
              form.auditFlagName
            }}</span>
            <span
              v-else-if="form.auditFlag === 2"
              class="shenhe-title warning"
              >{{ form.auditFlagName }}</span
            >
            <span
              v-else-if="form.auditFlag === 3"
              class="shenhe-title success"
              >{{ form.auditFlagName }}</span
            >
            <span v-else-if="form.auditFlag === 4" class="shenhe-title danger">{{ form.auditFlagName }}：{{ form.auditRemark }}</span>
          </div>
        </el-form-item>
        <el-form-item prop="files" label="营业执照：">
          <uploadFile
            :limit="1"
            :sizeLimit="1024 * 10"
            :businessType="businessType"
            :file-list="fileList"
            @onSuccess="handleAvatarSuccess"
            typeErrorMsg="请上传jpg、jpeg、png、bmp格式的文件"
            @onRemove="onRemove"
            :file-list-type="listType"
          >
            <el-button size="small" plain>
              <i class="iconfont iconshangchuan file-icon"></i>
              上传文件
            </el-button>
            <div slot="tip" class="el-upload__tip upload-title">
              *请上传原件照片或彩色扫描件，支持图片格式jpg、jpeg、png、bmp，不超过10M。
              <el-button size="medium" type="text" v-popover:popover>
                查看示例
              </el-button>
              <el-popover
              ref="popover"
                placement="top-start"
                trigger="hover"
              >
              <img style="width:150px" src="../../assets/img/license.png" alt="">
              
              </el-popover>
            </div>
          </uploadFile>
        </el-form-item>

        <el-form-item prop="companyName" label="企业名称：">
          <el-input
            placeholder="请输入企业名称"
            v-model="form.companyName"
            maxlength="50"
            minlength="3"
            clearable
            size="medium"
          ></el-input>
        </el-form-item>

        <el-form-item prop="usciType" label="证件号码类型：">
          <el-select
            v-model="form.usciType"
            placeholder="请选择证件号码类型"
            clearable
            size="medium"
          >
            <el-option
              v-for="item in options"
              :key="item.certType"
              :label="item.certTypeName"
              :value="item.certType"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="usci" label="证件号码：">
          <el-input
            placeholder="请输入证件号码"
            v-model="form.usci"
            maxlength="18"
            clearable
            size="medium"
          ></el-input>
        </el-form-item>

        <el-form-item prop="licenseStartTime" label="成立日期：">
          <el-date-picker
            v-model="form.licenseStartTime"
            type="date"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="请选择成立日期"
            size="medium"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item prop="licenseLongTime" label="是否永久经营：">
          <el-switch
            v-model="form.licenseLongTime"
            active-text="是"
            inactive-text="否"
            :active-value="2"
            :inactive-value="1"
            @change="isLong(form.licenseLongTime)"
          >
          </el-switch>
        </el-form-item>

        <el-form-item
          v-if="form.licenseLongTime === 1"
          prop="licenseEndTime"
          label="营业期限："
        >
          <el-date-picker
            v-model="form.licenseEndTime"
            type="date"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="请选择结束日期"
            size="medium"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item prop="legalPersonName" label="法定代表人姓名：">
          <el-input
            placeholder="请输入法定代表人姓名"
            v-model="form.legalPersonName"
            maxlength="20"
            clearable
            size="medium"
          ></el-input>
        </el-form-item>

        <el-form-item prop="legalPersonIdcard" label="法定代表人身份证：">
          <el-input
            placeholder="请输入法定代表人身份证号码"
            v-model="form.legalPersonIdcard"
            maxlength="18"
            clearable
            size="medium"
            onkeyup="value=value.replace(/[^\w\.\/]/ig,'')"
          ></el-input>
        </el-form-item>

        <el-form-item prop="companyEmail" label="企业邮箱：">
          <el-input
            placeholder="请输入企业邮箱"
            v-model="form.companyEmail"
            maxlength="30"
            clearable
            size="medium"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button @click="goBack" size="medium" round plain>取消</el-button>
          <el-button type="primary" @click="submit" size="medium" round
            >保存提交</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { getUserInfo } from '@/utils/auth.js'
import regeExp from '../../utils/regeExp.js'
export default {
  data() {
    // 邮箱自定义验证规则
    var validateEmail = (rules, value, callback) => {
      if (value === '') {
        callback(new Error('请填写企业邮箱'))
      } else if (!regeExp.emailReg(value)) {
        callback(new Error('邮箱输入有误'))
      } else {
        callback()
      }
    }
    // 法定代表人姓名自定义验证
    var validatePersonName = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('法定代表人姓名不能为空'))
      } else if (!regeExp.personNameReg(value)) {
        callback(new Error('法定代表人姓名只能由中文和字母组成'))
      } else {
        callback()
      }
    }
    return {
      companyId: JSON.parse(getUserInfo()).companyId,
      title: '企业资料完善/变更',
      // 文件信息
      options: [],
      businessType: 'company', // 业务类型（公司文件）
      listType: 'picture', // 显示列表类型
      fileList: [],
      // 表单对象
      form: {
        licenseLongTime: 1 // 是否永久经营
      },
      rules: {
        files: [
          {
            type: 'array',
            required: true,
            message: '请上传营业执照',
            trigger: 'blur'
          }
        ],
        companyName: [
          {
            type: 'string',
            required: true,
            message: '请填写企业名称',
            trigger: 'blur'
          },
          {
            min: 3,
            max: 50,
            message: '长度在 3 到 50 个字符',
            trigger: 'blur'
          }
        ],
        usciType: [
          { required: true, message: '请选择公司证件类型', trigger: 'blur' }
        ],
        usci: [
          {
            type: 'string',
            required: true,
            message: '请填写证件号码',
            trigger: 'blur'
          },
          { pattern: /^[A-Z0-9]+$/, message: '只能输入数字和大写字母' }
        ],
        licenseStartTime: [
          {
            required: true,
            message: '请选择成立日期',
            trigger: 'blur'
          }
        ],
        licenseEndTime: [
          {
            required: true,
            message: '请选择营业期限',
            trigger: 'blur'
          }
        ],
        legalPersonName: [
          { type: 'string', required: true, validator: validatePersonName }
        ],
        legalPersonIdcard: [
          { type: 'string', required: true, trigger: 'blur', message: '请填写企业法定代表人身份证号码' },
          { pattern: /^(\d{18,18}|\d{15,15}|\d{17,17}X)$/, message: '请输入正确的身份证号' }
        ],
        companyEmail: [
          { required: true, trigger: 'blur', validator: validateEmail }
        ]
      }
    }
  },
  computed: {},
  watch: {},
  created() {
    // 获取公司信息
    this.getCompany()
    this.listCompanyCertType()
  },
  methods: {
    // 页面返回
    goBack() {
      this.$router.push('/setting')
    },
    // 获取公司信息
    async getCompany() {
      const { data } = await this.$http.accountSetting.getCompany({
        companyId: this.companyId
      })
      if (data.code !== '0') {
        return this.$message.error(data.msg || '访问不成功！')
      } else {
        console.log('数据:', data)
        this.form = data.data
        const file = {
          name: data.data.files[0].fileName,
          url: data.data.files[0].fileUrl
        }
        this.fileList.push(file)
      }
    },
    // 获取公司类型ID
    async listCompanyCertType() {
      const { data } = await this.$http.accountSetting.listCompanyCertType({
        companyId: this.companyId
      })
      if (data.code !== '0') {
        return this.$message.error(data.msg || '访问不成功！')
      } else {
        console.log('数据:', data.data)
        this.options = data.data
      }
    },
    // 营业期限选择
    isLong(longTime) {
      this.form.licenseEndTime = ''
    },
    // 表单提交
    submit() {
      this.$refs['form'].validate(async(valid) => {
        if (!valid) return
        else {
          this.updateCompany()
        }
      })
    },

    // 修改公司
    async updateCompany() {
      this.loading = true
      const params = { ...this.form }
      const { data } = await this.$http.accountSetting.updateCompany(params)
      if (data.code === '0') {
        console.log('数据：', data)
        this.$message.success(data.msg || '修改成功')
        this.$router.push('/setting')
      } else {
        this.$message.error(data.msg || '获取列表失败')
      }
      this.loading = false
    },

    /** 文件上传相关 */
    handleAvatarSuccess(res, file) {
      if (res.code === '0') {
        this.form.files = res.data
        console.log('files:', res.data)
        this.$refs.form.validateField('files')
        // this.imgUrl = URL.createObjectURL(file.raw)
        // this.fileList = [{ name: res.data[0].originalName, fileUrl: res.data[0].file }]
      } else {
        this.$message.error(res.msg || '添加失败')
      }
    },
    onRemove() {
      console.log('onRemove')
      this.form.files = []
    }
  }
}
</script>

<style lang="scss">
.change-content-page {
  .warning {
    color: #e6a23c !important;
  }
  .success {
    color: #67c23a !important;
  }
  .danger {
    color: #f56c6c !important;
  }

  .shenhe-content {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .shenhe-icon {
    width: 20px;
    height: 20px;
  }

  .shenhe-title {
    font-size: 14px;
    margin-left: 10px;
  }

  .upload-title {
    margin: 12px 0;
    font-size: 14px;
    font-weight: 500;
    color: rgba(51, 51, 51, 1);
  }

  .el-input {
    width: 540px;
  }

  .upload-demo {
    width: 540px;
  }

  .file-icon {
    font-size: 10px;
    margin-right: 2px;
  }
}
</style>
